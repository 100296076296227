/* Custom styles for the slider */
/* .MuiSlider-root {
    color: #1976d2;
    height: 8px;
  }
  
  .MuiSlider-track {
    border: none;
  }
  
  .MuiSlider-thumb {
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 2px solid currentColor;
    margin-top: -8px;
    margin-left: -12px;
    box-shadow: 0 3px 1px rgba(0,0,0,0.1),
                0 2px 2px rgba(0,0,0,0.06),
                0 1px 5px rgba(0,0,0,0.1);
  }
  
  .MuiSlider-thumb:hover,
  .MuiSlider-thumb.Mui-focusVisible,
  .MuiSlider-thumb.Mui-active {
    box-shadow: 0 3px 1px rgba(0,0,0,0.1),
                0 2px 2px rgba(0,0,0,0.06),
                0 1px 5px rgba(0,0,0,0.1);
  }
  
  .MuiSlider-valueLabel {
    left: calc(-50% + 4px);
  }
  
  .MuiSlider-valueLabel > span {
    transform: rotate(45deg);
    text-align: center;
    background: transparent;
    color: #000;
  }
  
  .MuiSlider-markLabel {
    font-size: 0.75rem;
    color: #000;
  }
  
  .MuiSlider-markLabelActive {
    color: #1976d2;
  } */
  
  /* .times-box { 
    padding: 40px !important;
    margin: 40px !important;
  } */

  .times-slider-container {
    /* padding-left: 40px;
    padding-right: 40px; */
    /* margin-left: 0 40px; */
    padding: 0 20px;
  }