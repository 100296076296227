.PromptApp {
    /* font-family: sans-serif;
    text-align: center; */
    display: flex;
    background-color: transparent; 
    /* height: 100vh;
    margin: 0; */
  }
  
  input[type="text"] {
    padding: 8px;
    width: 100%;
  }

  .left-column {
    flex: 1;
    /* background: "#f0f0f0"; */
    background: transparent; 
    background-color: transparent; 
    padding: "20px";
}

.right-column {
  flex: 1;
  /* background: "#f0f0f0"; */
  background: transparent; 
  background-color: transparent; 
  padding: "20px";
}

.button-new-chat {
    background-color: transparent;
    /* background: "#f0f0f0";
    background-color: "#f0f0f0";
    background: "#f0f0f0"; */
}
  
  /* button {
    padding: 10px;
    margin: 5px;
    cursor: pointer;
  } */

  /* .prompt-button {
    border: solid 0.5px rgba(255, 255, 255, 0.5);
    background-color: transparent; 
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
  }
  
  #prompt-button {
    border: solid 0.5px rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 0, 0, 0.5);
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
  }
  
  button {
    border: solid 0.5px rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 0, 0, 0.5);
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
  } */
  
  /* style={{ backgroundColor: clicked[index] ? 'yellow' : 'white', margin: '10px' }} */
  /* Default button styling */
.prompt-button {
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    /* background-color: #202123; */
    /* background-color: white;  */
    color: rgba(255, 255, 255, 1.0);
    border: 1px solid #ccc; /* Optional: adds a border */
    outline: none; /* Removes the outline on focus */
  }
  
  /* Styling for when the button is clicked */
  .prompt-button-clicked {
    border: 4px solid #139df9;
    background-color: rgba(255, 255, 255);
    /* background-color: yellow; */
    color: #202123;
    /* color: rgba(255, 0, 0); */
  }