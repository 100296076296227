.travelgpt-container {
  color:#fff;
  font-family: 'Open Sans', sans-serif;
  height: calc(100vh - 60px);
  overflow: hidden;
  /* height: calc(100vh - 60px); */
  /* background-color: #343541; */
  background-color: #ffffff;
  display: flex;
  /* flex-direction: column; */
  /* overflow: auto; #E31B5F */
}

.travelgpt-side-bar {
  font-size: 12px;
  background-color: #ffffff;
  max-height: 100%;
  overflow: auto;
  width: 244px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #b5b5b5; /* Adds a 1px solid black border */
}


.travelgpt-button {
  background-color: #f94349; 
  color: #FFFFFF;
  border: 1.5px solid #FFFFFF;
  /* background-color: transparent;  */
  /* border: 1.5px solid #B4B4B4;  */
  /* border: 1.5px solid #f43841; */ 
  /* border: 1.5px solid #565454; */
  border-radius: 15px; 
  /* color: #B4B4B4; */
  font-size: 12px;
  padding: 10px;
  margin: 20px 35px 0px 35px;
  transition: all 0.3s ease; 
  cursor: pointer; 
}

.travelgpt-button:hover {
  border: 2px solid #f94349; 
  font-weight: bold;
  /* border-color: #565454;  */
  /* color: #FFFFFF; */
  /* background-color: #F43841;
  border-color: #FFFFFF; 
  color: #FFFFFF; */
}

/* .travelgpt-button {
  border: solid 0.5px rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
} */

.travelgpt-history {
  padding: 10px;
  margin: 0px 10px 10px 10px;
  height: 100%;
  color: #565454;
}

.travelgpt-history li {
  list-style-type: none;
  padding: 10px 0;
  cursor: pointer;
}

.travelgpt-history li.selected {
  font-weight: bold; /* Makes the text bold */
}

.travelgpt-main {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  align-items: center;
  text-align: center;
  /* max-height: 100%; */
  /* height: 100%; */
  /* overflow: auto; */
  /* max-width: 1080px; */
  /* margin: 0px 70px; */
  /* justify-content: space-between; */
  /* overflow: hidden; */
  /* height: 100vh; */
  overflow: hidden;
}

.travelgpt-main.expanded .travel-gpt-main-chat-history {
  height: calc(100vh - 460px); /* Adjust this value based on the actual height of the bottom section and expandable table */
}

/* .travelgpt-main.expanded .travelgpt-bottom-section {
  transform: translateY(-400px);
} */

.travelgpt-main .travelgpt-bottom-section {
  transform: translateY(0);
}

.travelgpt-main.expanded .expandable-flight-table {
  max-height: 400px;
}

.travel-gpt-main-chat-history {
  width: 100%;
  padding: 0px 70px;
  /* max-width: 1080px; */
  flex-grow: 1; /* Allows this area to expand or shrink as needed */
  overflow-y: auto; /* Enables scrolling within the chat history */
  transition: flex-grow 0.3s ease-in-out; /* Smooth transition for resizing */
}

.expandable-flight-table {
  background-color: 'yellow';
  position: absolute;
  /* bottom: -800px; */
  /* bottom: -75%; */
  /* top: 75%; */
  top: calc(100vh - 360px);
  /* left: 208px; */
  right: 0;
  width: 100%;
  transition: height 0.3s ease-in-out; 
  height: 300px; 
  overflow: hide; 
  z-index: 9;

  max-height: 10px;
  overflow: hiddem;
  transition: max-height 0.3s ease-in-out;
}
/* .expandable-flight-table {
  position: fixed;
  bottom: 100px; 
  left: 0;
  right: 0;
  height: calc(100vh - 100vh + 100px); 
  overflow: hidden;
  transition: height 0.3s ease-in-out, bottom 0.3s ease-in-out;
} */

/* .expandable-flight-table.expanded {
  height: 50vh; 
} */




.travelgpt-info {
  color: rgba(255, 255, 255, 0.5);
  font-size: 11px;
  padding: 10px;
}

.travelgpt-bottom-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0 57px 0;
}
.travelgpt-input-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 650px;
  z-index: 10;
}

.travelgpt-chat-user-input {
  width: 100%;
  flex-grow: 1;
  resize: none; /* Prevent manual resizing */
  overflow: hidden; /* Hide scrollbars */
  border: none;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 12px 15px;
  border-radius: 5px;
  box-shadow: rgba(0,0,0,0.05) 5px 54px 55px,
      rgba(0,0,0,0.05) 0 -5px 30px,
      rgba(0,0,0,0.05) 0 4px 6px,
      rgba(0,0,0,0.05) 5px 5px 3px,
      rgba(0,0,0,0.05) 0 -3px 5px;
}

.travelgpt-chat-user-input:focus {
  outline: none;
}
.submit-button {
  margin-left: 10px;
  cursor: pointer;
  /* Add other styles for your submit button */
}

#submit {
  position: absolute;
  bottom: 12px;
  right: 15px;
  cursor: pointer;
}

.travelgpt-feed {
  /* overflow: scroll; */
  overflow-y:auto;
  width: 100%;
  padding: 0;
  text-align: left;
  color: #000000;
}

/* .travelgpt-feed li {
  display: flex;
  background-color: #444654;
  width: 100%;
  padding: 20px;
  margin: 20px 0;
} */
/* .travelgpt-feed li {
  display: flex;
  margin: 15px 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 20px 20px;
  border-radius: 5px;
  box-shadow: rgba(0,0,0,0.05) 5px 54px 55px,
      rgba(0,0,0,0.05) 0 -5px 30px,
      rgba(0,0,0,0.05) 0 4px 6px,
      rgba(0,0,0,0.05) 5px 5px 3px,
      rgba(0,0,0,0.05) 0 -3px 5px;
} */
.travelgpt-feed-li {
  display: flex;
  /* width: 95%; */
  margin: 15px 10px;
  /* padding: 5px; */
  border: none;
  /* color: #000000; */
  background-color: rgba(255, 255, 255, 0.05);
  padding: 20px 20px;
  border-radius: 5px;
  box-shadow: rgba(0,0,0,0.05) 5px 54px 55px,
      rgba(0,0,0,0.05) 0 -5px 30px,
      rgba(0,0,0,0.05) 0 4px 6px,
      rgba(0,0,0,0.05) 5px 5px 3px,
      rgba(0,0,0,0.05) 0 -3px 5px;
}
.chat-message {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.content {
  flex: 1; 
  display: flex;
  flex-direction: column; 
  padding-right:60px;
}
.message-flight-table {
  /* margin: 15px 10px;
  padding: 20px 20px; */
  margin: 0;
  padding: 0px 20px;
  border: none;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  box-shadow: rgba(0,0,0,0.05) 5px 54px 55px,
      rgba(0,0,0,0.05) 0 -5px 30px,
      rgba(0,0,0,0.05) 0 4px 6px,
      rgba(0,0,0,0.05) 5px 5px 3px,
}
.search-params-component {
  margin: 0;
  padding: 0px 20px;
}
/* .travelgpt-feed p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  text-align: left;
}

.travelgpt-feed per {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  text-align: left;
} */



.travelgpt-feed p, .travelgpt-feed pre {
  color: #1c1c1c;
  font-size: 12px;
  text-align: left;
  white-space: pre-wrap; /* Preserve whitespace but wrap long lines */
  word-wrap: break-word; /* Break long words to fit the container */
  max-width: 100%; /* Ensure the content stays within the container */
}

.travelgpt-feed p.role {
  min-width: 60px;
}

.chat-title {
  color: #1c1c1c;
}



.flight-search-filters-container {
  position: relative;
  display: flex;
  align-items: flex-start;
}
.flight-search-filters-toggle-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0 5px 5px 0;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: left 0.3s, right 0.3s;
}
.flight-search-filters-toggle-button.open {
  left: 300px; /* Adjust based on the width of your sidebar */
  right: -30px; /* Ensure the button is just outside the container */
}

.flight-search-filters-app {
  position: absolute;
  left: -300px; /* Adjust based on the width of your sidebar */
  top: 0;
  width: 300px; /* Adjust based on the width of your sidebar */
  height: 100%;
  background-color: #f8f9fa;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: left 0.3s;
  z-index: 1;
}
.flight-search-filters-app.open {
  left: 0;
}






/* .flight-search-filters-app {
    background-color: #202123;
    max-height: 100%;
    overflow: auto;
    height: 100vh;
    width: 244px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} */

.flight-container {
    font-family: Arial, sans-serif;
    width: 100%;
    max-width: 600px;
    margin: auto;
    border: 1px solid #ccc;
    padding: 10px;
  }
  
  .flight {
    border-bottom: 1px solid #eee;
    padding: 10px 0;
  }
  
  .flight-times, .flight-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .layover {
    text-align: right;
    color: red;
  }
  
  .destination-button {
    border: solid 0.5px rgba(255, 255, 255, 0.5);
    background-color: transparent;
    border-radius: 5px;
    padding: 6px;
    margin: 6px;
    font-size: 12px;
}






.context-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.context-menu button {
  display: block;
  width: 100%;
  padding: 8px 12px;
  border: none;
  background: none;
  cursor: pointer;
  text-align: left;
}

.context-menu button:hover {
  background-color: #f0f0f0;
}

.submit-button {
  color: black;
}




.ai-message {
  font-size: 12px;
  font-weight: normal; 
}

.ai-message h3 {
  font-size: 12px;
  padding: 10px 0 2px 0;
  margin: 0px;
  font-weight: normal; 
}
.ai-message li {
  margin: 0 0 0 20px; /* Remove extra margin from list items */
  padding: 0; /* Remove padding from list items */
  list-style-type: disc; /* Ensure proper bullet points */
}

.ai-message strong {
  font-weight: normal; 
}