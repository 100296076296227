/* src/components/Navbar.css */

.navbar {
    /* background-color: #000;  */
    /* color: #fff; */
    /* color: #6A6A6A; */
    /* color: black; */
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px; /* Adjust as needed */
    height: 60px; /* Adjust as needed */
    border-bottom: 1px solid #b5b5b5;
  }
  
  .navbar-container {
    max-width: 1200px; /* Adjust as needed */
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-brand {
    font-size: 1.8rem; /* Adjust as needed */
    color: #F94348;
    text-decoration: none;
  }
  
  .navbar-links {
    display: flex;
  }
  
  .navbar-item {
    position: relative;
  }
  
  .navbar-link {
    display: flex;
    align-items: center;
    height: 60px; /* Adjust as needed */
    padding: 0 15px; /* Adjust as needed */
    /* color: #fff; */
    color: #565454;
    text-decoration: none;
    /* font-weight: 500;  */
    font-size: 14px; /* Adjust as needed */
  }
  
  .navbar-link:hover {
    /* background-color: #333; */
    font-weight: 500;
    color: #000000;
  }

  .navbar-link.active {
    font-weight: 600;
    color: #2d2c2c;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .navbar-item:hover .dropdown-content {
    display: block;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    background-color: #fff;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  
  .navbar-actions {
    display: flex;
    align-items: center;
  }
  
  .navbar-action {
    padding: 10px 15px;
    margin-left: 10px;
    color: #fff;
    text-decoration: none;
  }
  
  /* .navbar-action-special {
    color: #0B63CE;
    border: 1px solid #0B63CE;
    border-radius: 4px;
    padding: 10px 20px;
  }
  
  .navbar-action-special:hover {
    background-color: #0B63CE;
    color: #fff;
  } */

  .user-profile {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .user-picture {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 20px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #c4c4c4;
    color: black;
    border-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start (left) */
  }
  
  .dropdown-menu p {
    margin: 0;
    padding: 5px 0;
    font-size: 16px;
  }
  
  .sign-out-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }
  
  .sign-out-button:hover {
    background-color: #d32f2f;
  }