@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


* {
    /* color:#fff; */
    font-family: 'Open Sans', sans-serif;
}

body {
    margin: 0;
    padding: 0;
}

.chatgpt-container {
    color:#fff;
    font-family: 'Open Sans', sans-serif;
    height: calc(100vh - 60px);
    background-color: #343541;
    display: flex;
    overflow: auto;
}

.chatgpt-side-bar {
    background-color: #202123;
    max-height: 100%;
    overflow: auto;
    width: 244px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chatgpt-button {
    border: solid 0.5px rgba(255, 255, 255, 0.5);
    background-color: transparent;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
}

.history {
    padding: 10px;
    margin: 10px;
    height: 100%;
}

.history li {
    list-style-type: none;
    padding: 15px 0;
    cursor: pointer;
}

.main-briangpt {
    max-height: 100%;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.info {
    color: rgba(255, 255, 255, 0.5);
    font-size: 11px;
    padding: 10px;
}

.bottom-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.input-container {
    position: relative;
    width: 100%;
    max-width: 650px;
}

.chat-user-input {
    width: 100%;
    border: none;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 12px 15px;
    border-radius: 5px;
    box-shadow: rgba(0,0,0,0.05) 0 54px 55px,
        rgba(0,0,0,0.05) 0 -12px 30px,
        rgba(0,0,0,0.05) 0 4px 6px,
        rgba(0,0,0,0.05) 0 12px 3px,
        rgba(0,0,0,0.05) 0 -3px 5px;
}

.chat-user-input:focus {
    outline: none;
}

#submit {
    position: absolute;
    bottom: 12px;
    right: 15px;
    cursor: pointer;
}

.feed {
    overflow: scroll;
    width: 100%;
    padding: 0;
    text-align: left;
}

.feed li {
    display: flex;
    background-color: #444654;
    width: 100%;
    padding: 20px;
    margin: 20px 0;
}

.feed p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    text-align: left;
}

.feed per {
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    text-align: left;
}

.feed p.role {
    min-width: 100px;
}
