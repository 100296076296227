/* .filters,
.filters * { */
/* .filters {
  position: fixed;
  top: 10%;
  right: 10%;
  width: 300px;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color:#000000 !important;
  padding: 20px;
  z-index: 1000;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.filters {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-height: 100%; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color:#000000 !important;
  padding: 20px;
  z-index: 1000;
}

.filters * {
  color: #000000 !important;
}

.filters-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filters-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000000;
}


.filters-header button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.filters-section {
  margin-bottom: 20px;
}

.filters-stops,
.filters-airlines,
.filters-bags,
.filters-price,
.filters-times,
.filters-layover,
.filters-airports,
.filters-duration {
  display: flex;
  flex-direction: column;
}

.filters-stops label,
.filters-airlines label,
.filters-airports label {
  margin-bottom: 10px;
}

.select-all {
  display: flex;
  align-items: center;
}

.filter-option {
  display: flex;
  flex-direction: row; 
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
  padding: 5px 0;
  justify-content: flex-start !important;
  width: 100%; /* Ensure the label takes the full width */
}

.filter-option span {
  display: inline-block;
  vertical-align: middle;
  align-items: left;
}

.filter-option input[type="radio"] {
  margin-right: 10px;
  box-shadow: none;
  appearance: auto; 
}

.filters-section h4 {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.filters-bags {
  display: flex;
  align-items: center;
}

.filters-bags button {
  width: 30px;
  height: 30px;
  margin: 0 10px;
}

.filters-price,
.filters-layover,
.filters-duration {
  display: flex;
  align-items: center;
}

.filters-price input[type="range"],
.filters-layover input[type="range"],
.filters-duration input[type="range"] {
  flex: 1;
  margin-right: 10px;
}

.filters-footer {
  text-align: center;
}

.filters-footer button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
}
