/* body {
    text-align: center;
} */
video {
    width: 0px;
    height: 0px;
}
#c1 {
    width: 800px;
    height: 600px;
}
table {
    width: auto;
    margin: auto;
}
tr, td {
    border: 0px;
    /* text-align: center;  */
}